// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contributions-js": () => import("./../../../src/pages/contributions.js" /* webpackChunkName: "component---src-pages-contributions-js" */),
  "component---src-pages-education-js": () => import("./../../../src/pages/education.js" /* webpackChunkName: "component---src-pages-education-js" */),
  "component---src-pages-employment-dumaurier-js": () => import("./../../../src/pages/employment/dumaurier.js" /* webpackChunkName: "component---src-pages-employment-dumaurier-js" */),
  "component---src-pages-employment-js": () => import("./../../../src/pages/employment.js" /* webpackChunkName: "component---src-pages-employment-js" */),
  "component---src-pages-employment-retail-express-js": () => import("./../../../src/pages/employment/retail-express.js" /* webpackChunkName: "component---src-pages-employment-retail-express-js" */),
  "component---src-pages-employment-unipro-js": () => import("./../../../src/pages/employment/unipro.js" /* webpackChunkName: "component---src-pages-employment-unipro-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-interests-js": () => import("./../../../src/pages/interests.js" /* webpackChunkName: "component---src-pages-interests-js" */),
  "component---src-pages-preamble-js": () => import("./../../../src/pages/preamble.js" /* webpackChunkName: "component---src-pages-preamble-js" */),
  "component---src-pages-standards-js": () => import("./../../../src/pages/standards.js" /* webpackChunkName: "component---src-pages-standards-js" */),
  "component---src-pages-william-hill-js": () => import("./../../../src/pages/william_hill.js" /* webpackChunkName: "component---src-pages-william-hill-js" */)
}

